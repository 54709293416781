<template>
    <b-overlay :show="isLoadingData">
        <b-card>
            <div class="head-btn">
                <b-button
                    v-if="can('fivesclub_web_owners_show_staff_contacts_add_button')"
                    :to="{ name: 'create-staff-contact'}"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                >
                    <feather-icon icon="PlusIcon" style="color: white" />
                </b-button>
            </div>

            <b-table
                ref="refStaffListTable"
                :items="staffContacts"
                :fields="tableColumns"
                sort-by="priority"
                show-empty
                responsive
                sticky-header="400px"
                empty-text="No hay registros"
                class="position-relative mb-0"
            >
                <template #cell(priority)="data">
                    <!-- id: {{ data.item.id }} - index: {{ data.index }} - order: {{ data.item.priority }}<br> -->
                    <div class="text-nowrap">
                        <b-button
                            v-if="data.index != 0"
                            size="sm"
                            variant="link"
                            class="p-custom-staff"
                            @click="setNewOrder(data.item, ((data.index+1) - 1))"
                        >
                            <feather-icon icon="ArrowUpIcon" size="12" /> <!-- {{((data.index+1) - 1)}} -->
                        </b-button>

                        <b-button
                            v-if="data.index != (staffContacts.length - 1)"
                            size="sm"
                            variant="link"
                            class="p-custom-staff"
                            @click="setNewOrder(data.item, ((data.index+1) + 1))"
                        >
                            <feather-icon icon="ArrowDownIcon" size="12" /> <!-- {{((data.index+1) + 1)}} -->
                        </b-button>
                    </div>
                </template>

                <template #cell(status)="row">
                    <span class="text-nowrap">
                        <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
                            {{ row.item.status ? "Activo" : "Desactivado" }}
                        </b-badge>
                    </span>
                </template>
                <template #cell(actions)="row">
                    <div class="d-flex justify-content-center">
                        <b-button size="sm" :to="{ name: 'edit-staff-contact', params: { id: row.item.id } }" variant="primary" v-if="can('fivesclub_web_owners_show_staff_contacts_edit_button')">
                            <feather-icon icon="Edit2Icon" size="12" />
                        </b-button>

                        <b-button variant="danger" @click.prevent="deleteContact(row.item.id)" size="sm" class="ml-1" v-if="can('fivesclub_web_owners_show_staff_contacts_delete_button')">
                            <feather-icon icon="TrashIcon" size="12" />
                        </b-button>
                    </div>
                </template>
            </b-table>

        </b-card>
    </b-overlay>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Ripple from 'vue-ripple-directive'
import { acl } from "@/modules/auth/mixins/acl"

export default {
	mixins: [acl],
    directives: { Ripple, },
    async created(){
        await this.init();
    },
    data(){
        return {
            isLoadingData: false,
            tableColumns: [
                { key: 'priority', label: 'Orden', class: 'text-center', formatter: value => {
                    return !!value ? value : '--'
                }},
                { key: 'fullname', label: 'Nombre', class: 'text-center', formatter: value => {
                    return !!value ? value : '--'
                }},
                { key: 'jobTitle', label: 'Puesto', class: 'text-center', formatter: value => {
                    return !!value ? value : '--'
                }},
                // { key: 'email', label: 'Email', class: 'text-center', formatter: value => {
                //     return !!value ? value : '--'
                // }},
                { key: 'url', label: 'Enlace de reunión', class: 'text-center', formatter: value => {
                    return !!value ? value : '--'
                }},
                { key: 'status', label: 'Estado', class: 'text-center'},
                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ]
        }
    } ,
    computed: {
        ...mapState('start', ["hotels"]),
        ...mapState("auth", ["user"]),
        ...mapState("fivesClubCatalogs", ["staffContacts"]),
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ["getInitialContentForStaffContact", "updateStaffOrder" ,"deleteStaffOrder"]),
        ...mapMutations('fivesClubCatalogs',['setStaffContacts']),
        ...mapMutations('start',['setHotels']),

        async init(){
            this.isLoadingData = true
            const { staffContact } = await this.getInitialContentForStaffContact()
            if (staffContact.length > 0) this.setStaffContacts(staffContact)

            this.isLoadingData = false
        },
        async setNewOrder(item, to){
            const reorderedData = structuredClone(this.staffContacts)
            const from = structuredClone(item.priority)

            const idxPrevious = reorderedData.findIndex(data=> data.priority == to)
            const idxNew = reorderedData.findIndex(data=> data.priority == from)

            reorderedData[idxPrevious].priority = item.priority
            reorderedData[idxNew].priority = to

            reorderedData.sort((a, b) => a.priority > b.priority ? 1 : -1).forEach((data, index) => {
                if (data.id != item.id) data.priority = index + 1
            })

            const data = reorderedData.map(data => {
                return {
                    id: data.id,
                    priority: data.priority
                }
            })

            const response = await this.updateStaffOrder({ids: data})
            if (response) await this.init()
        },
        async deleteContact(id){
            const response = await this.deleteStaffOrder({id})
            if (response) await this.init()
        }
    },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.head-btn{
display: flex;
justify-content: flex-end;
margin-bottom: 1rem;
}

.actions-status{
display: flex;
align-items: center;
gap: 1rem;
}

.p-custom-staff{
padding: 0.35rem;
}

</style>
